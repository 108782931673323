import React from 'react';
import logo from './assets/ICC.webp';
import './App.css';
import ChangePassword from './views/ChangePassword';
import ForgotPassword from './views/ForgotPassword';
import Home from './views/Index';
import Login from './views/Login';

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const changePassword = queryParams.get('changePassword');
  const forgotPassword = queryParams.get('forgotPassword');
  const isLoggedIn = true; // Replace with actual login check logic

  let content;
  if (changePassword) {
    content = <ChangePassword />;
  } else if (forgotPassword) {
    content = <ForgotPassword />;
  } else if (isLoggedIn) {
    content = <Home />;
  } else {
    content = <Login />;
  }

  return (
    <>
      {content}
    </>
  );
}

export default App;
