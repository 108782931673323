import React from 'react';

const Footer = () => {
  return (
    <div className="bg-neutral-700 w-full h-12 mt-auto text-white md:flex items-center hidden">
      <p className="mx-auto">
        &copy; ICC BPO 2024
      </p>
    </div>
  );
};

export default Footer;