import React from 'react';
import Header from '../components/page/Header';
import Body from '../components/page/Body';
import Footer from '../components/page/Footer';

const Index = () => {
  return (
    <div className="bg-slate-100 w-full h-full flex flex-col">
      <Header />
      <Body />
      <Footer />
    </div>
  );
};

export default Index;