import React, { useEffect } from 'react';

const Cst = () => {
  useEffect(() => {
    document.getElementById('module').textContent = 'Coachings';
  }, []);

  return (
    <div>
      {/* Your component's JSX goes here */}
    </div>
  );
};

export default Cst;