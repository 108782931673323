/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const Content = () => {
  return (
    <div className="flex flex-col w-full">
      <div id="breadCrumb" className="w-full h-8 bg-main-dark text-white flex px-2 py-1 font-bold">
        <a href="#" className="cursor-pointer" id="module">Module</a>
        <span className="mx-3" id="breadcrumbLink"> {'>'} </span>
        <a className="cursor-pointer" id="subModule"></a>
      </div>
      <div id="content" className="flex w-full h-full">
        <p className="mx-auto">
          Aquí va todo el contenido de la página
        </p>
      </div>
    </div>
  );
};

export default Content;