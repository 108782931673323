import React, { useState } from 'react';

const ChangePassword = () => {
  const [userInfo, setUserInfo] = useState({
    name: "Jose Luis Chajtur",
    email: "jchajtur@iccbpo.com"
  });

  return (
    <div className="h-full w-full flex items-start justify-center bg-main-dark py-8 lg:items-center md:py-24">
      <div className="flex flex-col items-center rounded-xl bg-white w-5/6 p-4 text-main-dark font-bold md:w-5/6 lg:w-2/3 xl:w-1/2">
        <img src="/assets/ICC.webp" alt="Logo" className="w-3/4 md:w-1/2" />
        <div className="flex flex-col w-full mt-5">
          <h4 className="text-center">
            Cambiar la contraseña para
          </h4>
          <h5 className="text-lg font-bold text-center text-main-light">
            {`${userInfo.name} / ${userInfo.email}`}
          </h5>
          <div className="flex flex-col md:flex-row justify-center md:justify-between">
            <div className="flex flex-col md:w-1/2">
              <div className="form-group mt-3 flex flex-col">
                <label htmlFor="password" className="font-bold">Nueva Contraseña</label>
                <div className="relative">
                  <input type="password" id="password" className="p-2 border-2 border-sky-950 rounded w-full" required />
                  <div id="togglePassword" className="absolute inset-y-0 right-0 p-2 cursor-pointer">
                    <i className="fa-regular fa-eye text-main-dark"></i>
                  </div>
                </div>
              </div>
              <div className="form-group mt-3 flex flex-col">
                <label htmlFor="confirmPassword" className="font-bold">Confirmar Contraseña</label>
                <div className="relative">
                  <input type="password" id="confirmPassword" className="p-2 border-2 border-sky-950 rounded w-full" />
                  <div id="toggleConfirmPassword" className="absolute inset-y-0 right-0 p-2 cursor-pointer">
                    <i className="fa-regular fa-eye text-main-dark"></i>
                  </div>
                </div>
              </div>
              <div className="flex justify-end mt-2">
                <button id="changePasswordButton" type="button" className="btn-success">Guardar</button>
              </div>
            </div>
            <div className="">
              <div className="mt-5 md:mt-8 text-main-light font-bold">La nueva contraseña debe incluir</div>
              <ul className="text-xs" id="passwordRequirements">
                <li className="text-red-800"><i className="fa-solid fa-x text-red-800 me-3"></i>Al menos 12 characters xxxxxxxxxxxx</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;