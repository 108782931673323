import React from 'react';

const ForgotPassword = () => {
  return (
    <div className="h-full w-full flex items-start justify-center bg-main-dark py-8 lg:items-center md:py-24">
      <div className="flex flex-col items-center rounded-xl bg-white w-5/6 p-4 text-main-dark font-bold md:w-1/2 lg:w-1/4">
        <img src="/assets/ICC.webp" alt="Logo" className="w-3/4 md:w-1/2" />
        <div className="flex flex-col w-5/6 mt-5">
          <p className="flex text-justify">
            Favor ingrese el correo electrónico con el que se registró en el sistema. Le enviaremos un correo con un enlace para restablecer su contraseña.
          </p>
          <input type="text" id="email" className="p-2 border-2 rounded mt-2" placeholder="Correo de ICC BPO" />
          <div className="flex justify-end mt-2">
            <button id="sendEmail" className="bg-main-dark text-white">
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;