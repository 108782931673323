import React, { useState, useEffect } from 'react';

const Clients = () => {
  const [clientContent, setClientContent] = useState('');

  useEffect(() => {
    // Load the initial client list content
    const loadClientList = async () => {
      try {
        const response = await fetch('/components/clients/clientList.php');
        const data = await response.text();
        setClientContent(data);
      } catch (error) {
        console.error('Error loading client list:', error);
        setClientContent('Error loading client list');
      }
    };

    loadClientList();
  }, []);

  const handleAddClientClick = async () => {
    try {
      const response = await fetch('/components/clients/addClient.php');
      const data = await response.text();
      setClientContent(data);
    } catch (error) {
      console.error('Error loading add client form:', error);
      setClientContent('Error loading add client form');
    }
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="text-2xl text-center">
        Administrador de Clientes
      </div>
      <div className="flex justify-center w-full">
        <div className="flex-col justify-between w-5/6 md:w-1/2">
          <div className="flex flex-row">
            <input type="text" className="border-2 border-main-dark p-1" placeholder="Buscar cliente" />
            {/* <button className="bg-main-dark text-white p-1">Buscar</button> */}
          </div>
          <button className="bg-main-dark text-white mt-2 btn-success" onClick={handleAddClientClick}>
            Agregar Cliente
          </button>
          <div id="clientContent" dangerouslySetInnerHTML={{ __html: clientContent }} />
        </div>
      </div>
    </div>
  );
};

export default Clients;