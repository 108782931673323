import React, { useEffect } from 'react';

const Home = () => {
  useEffect(() => {
    // Equivalent to $('#breadcrumbLink').show();
    const breadcrumbLink = document.getElementById('breadcrumbLink');
    if (breadcrumbLink) {
      breadcrumbLink.style.display = 'block';
    }

    // Equivalent to $('#subModule').text('Inicio');
    const subModule = document.getElementById('subModule');
    if (subModule) {
      subModule.textContent = 'Inicio';
    }
  }, []);

  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center">
          <p className="text-lg">12/25/2021</p>
          <p className="text-lg">Navidad</p>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-lg">12/31/2021</p>
          <p className="text-lg">Año Nuevo</p>
        </div>
      </div>
    </div>
  );
};

export default Home;