import React from 'react';
import cropped from '../../assets/cropped.jpg';

const Header = () => {
  return (
    <header className="w-full h-20 border border-b bg-zinc-200">
      <div className="flex flex-row md:justify-between justify-start ms-2 md:ms-0">
        <img src={cropped} alt="" className="w-auto h-8 md:h-10 lg:h-12 hidden md:block" />
        <div className="items-center hidden md:flex">
          Sección para notificaciones
        </div>
        <div className="mr-5 mt-2 flex flex-row items-center content-center text-main-light">
          <i className="fa-regular fa-circle-user fa-3x text-main-light h-full userImage"></i>
          <div className="flex flex-col ms-2">
            <div>José Luis Chajtur</div>
            <div className="text-blue-900 font-bold">IT Support Specialist</div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;