import React, { useState, useEffect } from 'react';

const ItCheck = () => {
  const [content, setContent] = useState('');

  useEffect(() => {
    document.getElementById('module').textContent = 'IT Checks';
    document.getElementById('breadcrumbLink').style.display = 'none';
    document.getElementById('subModule').textContent = '';

    // Fetch the content from the server
    const loadContent = async () => {
      try {
        const response = await fetch('components/itChecks/latest.php');
        const data = await response.text();
        setContent(data);
      } catch (error) {
        console.error('Error loading content:', error);
        setContent('Error loading content');
      }
    };

    loadContent();
  }, []);

  return (
    <div id="itCheckContent" className="w-full" dangerouslySetInnerHTML={{ __html: content }} />
  );
};

export default ItCheck;