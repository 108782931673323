import React, { useState, useEffect } from 'react';
import Clients from '../components/settings/Clients'; // Import other components as needed
import Roles from '../components/settings/Roles';
import Permissions from '../components/settings/Permissions';
import Files from '../components/settings/Files';
import Profile from '../components/settings/Profile';

const Settings = () => {
  const [activeModule, setActiveModule] = useState('clients');
  const [content, setContent] = useState(<Clients />); // Default content

  useEffect(() => {
    document.getElementById('module').textContent = 'Configuración';
    document.getElementById('breadcrumbLink').style.display = 'none';
    // Load the content based on the active module
    const loadContent = (module) => {
      switch (module) {
        case 'clients':
          setContent(<Clients />);
          break;
        case 'roles':
          setContent(<Roles />);
          break;
        case 'permissions':
          setContent(<Permissions />);
          break;
        case 'files':
          setContent(<Files />);
          break;
        case 'profile':
          setContent(<Profile />);
          break;
        default:
          setContent(<Clients />);
      }
    };

    loadContent(activeModule);
  }, [activeModule]);

  const handleModuleClick = (module) => {
    setActiveModule(module);
  };

  return (
    <div className="h-full w-full flex flex-col">
      <div id="settingsContent">
        {content}
      </div>
      <div className="flex flex-row h-12 w-full mt-auto justify-evenly">
        {['clients', 'roles', 'permissions', 'files', 'profile'].map((module) => (
          <div
            key={module}
            className={`hrIcon flex flex-col w-full items-center p-2 cursor-pointer justify-center ${
              activeModule === module ? 'bg-white text-main-dark' : 'bg-main-dark text-white'
            }`}
            data-group={module}
            onClick={() => handleModuleClick(module)}
          >
            <i className={`fa-solid fa-${getIconClass(module)}`}></i>
            <p className="text-xs">{getModuleName(module)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const getIconClass = (module) => {
  switch (module) {
    case 'clients':
      return 'user-tie';
    case 'roles':
      return 'users-gear';
    case 'permissions':
      return 'user-check';
    case 'files':
      return 'folder-open';
    case 'profile':
      return 'circle-user';
    default:
      return '';
  }
};

const getModuleName = (module) => {
  switch (module) {
    case 'clients':
      return 'Clientes';
    case 'roles':
      return 'Roles';
    case 'permissions':
      return 'Permisos';
    case 'files':
      return 'Archivos';
    case 'profile':
      return 'Perfil';
    default:
      return '';
  }
};

export default Settings;